import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import { lineFix } from '~modules/premium-subscription/premium-subscription.utils';
import RichText from '~components/RichText';
import Carousel from './components/PremiumCarousel';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '66px',
    marginBottom: '36px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '40px',
      lineHeight: '48px'
    }
  },
  imageMedia: {
    width: '100%',
    display: 'flex',
    maxWidth: '200px',
    borderRadius: '50%',
    border: '3px solid #4B5161',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      marginBottom: '20px'
    }
  },
  gridContainer: {
    display: 'flex !important'
  },
  content: {
    textAlign: props => props.textAlign,
    ...theme.typography.subtitle1,
    '& > h1': {
      ...theme.typography.h2,
      fontSize: '3.2rem',
      textAlign: 'center',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockEnd: '-15px'
    },
    '& > h2': {
      fontSize: '2.5rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '1em',
      marginBlockEnd: '0.5em',
      letterSpacing: '0em'
    },
    '& > h3': {
      fontSize: '2rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.4em',
      letterSpacing: '0em'
    },
    '& > h4': {
      fontSize: '1.7rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.35em',
      letterSpacing: '0em'
    },
    '& > h5': {
      ...theme.typography.h5,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > h6': {
      ...theme.typography.h6,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > p': {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '28px'
    },
    '& > ol, ul': {
      ...theme.typography.subtitle1
    },
    '& img': {
      maxWidth: '100%',
      display: 'flex',
      margin: '0 auto'
    },
    '& > p > .align-left': {
      textAlign: 'left',
      display: 'block'
    },
    '& > p > .align-right': {
      textAlign: 'right',
      display: 'block'
    },
    '& > pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    },
    '& > pre > iframe': {
      [theme.breakpoints.only('xs')]: {
        maxWidth: '100%',
        maxHeight: '100%'
      }
    },
    '& > p > .align-center': {
      textAlign: 'center',
      display: 'block'
    }
  },
  staffPickTitle: {
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '42px'
  },
  containerSpacing: {
    paddingTop: '80px',
    paddingBottom: '100px'
  },
  subtitle: {
    marginBottom: '90px',
    fontSize: '20px',
    lineHeight: '30px'
  },
  buttonStyle: {
    padding: '12px 24ppx',
    boxShadow: 'unset',
    textTransform: 'none',
    marginTop: '7px'
  }
}));

const PremiumContentStaffPicks = ({ title, subtitle, background_color, staffPicks }) => {
  const classes = useStyles();
  return (
    <Container center background={background_color} className={classes.containerSpacing}>
      <Typography variant="h4" className={classes.heading}>
        {lineFix(title)}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {lineFix(subtitle)}
      </Typography>
      <Carousel>
        {staffPicks &&
          staffPicks.map(pick => (
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="flex-start"
              className={classes.gridContainer}
              key={pick.title}
            >
              <Grid item xs={12} md={2}>
                <img src={pick.avatar.url} alt={pick.avatar.alt} className={classes.imageMedia} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="h5" className={classes.staffPickTitle}>
                  {lineFix(pick.title)}
                </Typography>
                <RichText externalClassName={classes.content} html={pick.text.html} />
                <Button
                  component={GatsbyLink}
                  to={pick.button.url}
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyle}
                >
                  {pick.button.title}
                </Button>
              </Grid>
            </Grid>
          ))}
      </Carousel>
    </Container>
  );
};

PremiumContentStaffPicks.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  background_color: PropTypes.string,
  staffPicks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string.isRequired
      }),
      button: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    })
  ).isRequired
};

PremiumContentStaffPicks.defaultProps = {
  background_color: '#fff'
};

export default PremiumContentStaffPicks;
