import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import { lineFix } from '~modules/premium-subscription/premium-subscription.utils';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 'bold',
    fontSize: '56px',
    lineHeight: '66px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '40px',
      lineHeight: '48px'
    }
  },
  content: {
    textAlign: props => props.textAlign,
    ...theme.typography.subtitle1,
    '& > h1': {
      ...theme.typography.h2,
      fontSize: '3.2rem',
      textAlign: 'center',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockEnd: '-15px'
    },
    '& > h2': {
      fontSize: '2.5rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '1em',
      marginBlockEnd: '0.5em',
      letterSpacing: '0em'
    },
    '& > h3': {
      fontSize: '2rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.4em',
      letterSpacing: '0em'
    },
    '& > h4': {
      fontSize: '1.7rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.35em',
      letterSpacing: '0em'
    },
    '& > h5': {
      ...theme.typography.h5,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > h6': {
      ...theme.typography.h6,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > p': {
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '30px'
    },
    '& > ol, ul': {
      ...theme.typography.subtitle1
    },
    '& img': {
      maxWidth: '100%',
      display: 'flex',
      margin: '0 auto'
    },
    '& > p > .align-left': {
      textAlign: 'left',
      display: 'block'
    },
    '& > p > .align-right': {
      textAlign: 'right',
      display: 'block'
    },
    '& > pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    },
    '& > pre > iframe': {
      [theme.breakpoints.only('xs')]: {
        maxWidth: '100%',
        maxHeight: '100%'
      }
    },
    '& > p > .align-center': {
      textAlign: 'center',
      display: 'block'
    }
  },
  containerSpacing: {
    marginBottom: '120px'
  }
}));

const PremiumContentInfo = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <Container className={classes.containerSpacing}>
      <Typography variant="h4" className={classes.heading}>
        {lineFix(title)}
      </Typography>
      <RichText externalClassName={classes.content} html={subtitle.html} />
    </Container>
  );
};

PremiumContentInfo.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string
  }).isRequired
};

export default PremiumContentInfo;
