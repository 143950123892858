import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fab from '@material-ui/core/Fab';
import NavigateNext from '@material-ui/icons/ArrowForward';
import NavigateBefore from '@material-ui/icons/ArrowBack';

import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  carouselBtn: {
    position: 'absolute',
    background: 'transparent',
    boxShadow: 'unset',
    opacity: '0.7',
    border: '2px solid #000;',
    top: '40%',
    zIndex: 1,
    [theme.breakpoints.up('xs')]: {
      top: '10%',
      left: props => (props.isNext ? null : 0),
      right: props => (props.isNext ? 0 : null)
    },
    [theme.breakpoints.up('sm')]: {
      top: '15%',
      left: props => (props.isNext ? null : 24),
      right: props => (props.isNext ? 24 : null)
    },
    [theme.breakpoints.up('md')]: {
      top: '40%',
      left: props => (props.isNext ? null : 40),
      right: props => (props.isNext ? 40 : null)
    },
    [theme.breakpoints.up('lg')]: {
      left: props => (props.isNext ? null : -24),
      right: props => (props.isNext ? -24 : null)
    },
    '@media only screen and (min-width: 1279px) and (max-width: 1300px)': {
      left: props => (props.isNext ? null : 8),
      right: props => (props.isNext ? 8 : null)
    }
  }
}));

const CarouselArrow = ({ onClick, isNext }) => {
  const classes = useStyles({ isNext });
  return (
    <Fab
      onClick={onClick}
      size="medium"
      className={classes.carouselBtn}
      aria-label={isNext ? 'navigate next' : 'navigate previous'}
    >
      {isNext ? <NavigateNext /> : <NavigateBefore />}
    </Fab>
  );
};

CarouselArrow.propTypes = {
  onClick: PropTypes.func,
  isNext: PropTypes.bool
};

CarouselArrow.defaultProps = {
  onClick: () => {},
  isNext: false
};

const Carousel = ({ children, ...rest }) => {
  const ensureArrayChildren = React.Children.toArray(children);

  const carouselSettings = {
    className: 'carousel slick-list slick-track',
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    swipeToSlide: true,
    infinite: ensureArrayChildren.length > 1,
    prevArrow: <CarouselArrow />,
    nextArrow: <CarouselArrow isNext />
  };

  return (
    <>
      <Container {...rest}>
        <Slider {...carouselSettings}>{ensureArrayChildren}</Slider>
      </Container>
      <style>{`
        .carousel {
          width: 100%;
        }
        .slick-list {
          padding: 8px 0;
          position: unset;
        }
        .slick-track {
          position: unset;
          margin-left: 0;
          margin-right: 0;
        }
        .slick-slider{
          position: unset;
        }
      `}</style>
    </>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired
};

export default Carousel;
