import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Container from '~components/Container';
import Link from '~components/Link';
import AnimatedPlayButton from '~components/AnimatedPlayButton';
import { planIdFromString } from '~utils/playlist-helpers';
import authorizeUser from '~utils/authorization-helper';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import RichText from '~components/RichText';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles(theme => ({
  guidesHeading: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    marginBottom: '14px'
  },
  guidesText: {
    fontSize: '18px',
    lineHeight: '28px'
  },
  moreVideos: {
    fontSize: '20px',
    lineHeight: '30px',
    marginTop: '27px'
  },
  infoText: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#2E72AC',
    marginBottom: '26px',
    display: 'block'
  },
  heroImage: {
    maxWidth: '100%',
    display: 'flex',
    height: 'auto'
  },
  ulStyle: {
    paddingInlineStart: `16px`,
    marginBlockStart: '20px',
    marginBlockEnd: '34px'
  },
  liStyle: {
    paddingBottom: '9px',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '400'
  },
  heroButton: {
    marginTop: '10px',
    padding: '20px 52px',
    fontSize: '18px',
    lineHeight: '21px',
    textTransform: 'none',
    boxShadow: 'unset',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px',
      textAlign: 'center'
    }
  },
  containerPosition: {
    marginTop: '64px',
    marginBottom: '80px'
  }
}));

const PremiumContentHero = ({
  playlists,
  playlistsOverline,
  guidesOverline,
  heroGuides,
  heroParshaLab,
  playlistsSubtitle,
  featuredPlaylist,
  playlistsButtonTitle
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
  const { isMarketingSite } = useConversionFramework();

  const { session } = useUserContext();

  const calculateGuideUrl = guide => {
    const requiredSubscriptionLevel = planIdFromString(guide.required_subscription_plan);
    const { hasAccess, redirectPath } = authorizeUser(
      requiredSubscriptionLevel,
      session,
      false,
      isMarketingSite
    );
    if (hasAccess) {
      return guide.url;
    }
    return redirectPath;
  };

  const calculateGuideState = guide => {
    const requiredSubscriptionLevel = planIdFromString(guide.required_subscription_plan);
    const { hasAccess, redirectProps } = authorizeUser(
      requiredSubscriptionLevel,
      session,
      false,
      isMarketingSite
    );
    if (hasAccess) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return redirectProps.state;
  };

  const calculateGuideTarget = guide => {
    const requiredSubscriptionLevel = planIdFromString(guide.required_subscription_plan);
    const { hasAccess } = authorizeUser(requiredSubscriptionLevel, session, false, isMarketingSite);
    if (hasAccess) {
      return '_blank';
    }
    return '_self';
  };

  return (
    <Container center className={classes.containerPosition}>
      <Grid
        container
        direction={isSmallerThanMd ? 'column-reverse' : 'row'}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} md={5}>
          <Typography variant="overline" className={classes.infoText}>
            {guidesOverline}
          </Typography>
          {heroParshaLab &&
            heroParshaLab.map(parshaLab => (
              <React.Fragment key={parshaLab.title}>
                <Typography variant="h4" className={classes.guidesHeading}>
                  {parshaLab.title}
                </Typography>
                <RichText
                  html={parshaLab.subtitle.html}
                  mode="premiumHeroGuides"
                  verticalSpacing={0}
                />
                <ul className={classes.ulStyle}>
                  {parshaLab.episodes &&
                    parshaLab.episodes.map(episode => (
                      <React.Fragment key={episode.title}>
                        {episode.url && episode.title && (
                          <li key={episode.title} className={classes.liStyle}>
                            <Link to={episode.url} underline="always">
                              {episode.title}
                            </Link>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                </ul>
              </React.Fragment>
            ))}
          {heroGuides &&
            heroGuides.map(heroGuide => (
              <React.Fragment key={heroGuide.title}>
                <Typography variant="h4" className={classes.guidesHeading}>
                  {heroGuide.title}
                </Typography>
                <Typography variant="subtitle1" className={classes.guidesText}>
                  {heroGuide.subtitle}
                </Typography>
                <ul className={classes.ulStyle}>
                  {heroGuide.guides &&
                    heroGuide.guides.map(guide => (
                      <li key={guide.title} className={classes.liStyle}>
                        <Link
                          to={calculateGuideUrl(guide)}
                          state={calculateGuideState(guide)}
                          target={calculateGuideTarget(guide)}
                          underline="always"
                        >
                          {guide.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </React.Fragment>
            ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="overline" className={classes.infoText}>
            {playlistsOverline}
          </Typography>
          {featuredPlaylist && (
            <Box
              position="relative"
              component={Link}
              to={featuredPlaylist.url}
              display="inline-block"
              width="100%"
            >
              <img
                className={classes.heroImage}
                src={featuredPlaylist.cover_image.url}
                alt={featuredPlaylist.cover_image.alt}
              />
              <AnimatedPlayButton />
            </Box>
          )}
          <Typography color="textSecondary" className={classes.moreVideos}>
            {playlistsSubtitle}
          </Typography>
          {playlists && playlists.length > 1 && (
            <ul style={{ paddingInlineStart: `16px` }}>
              {playlists.map(secondaryPlaylist => (
                <li
                  key={secondaryPlaylist.title}
                  style={{ paddingBottom: '8px', fontSize: '20px', lineHeight: '30px' }}
                >
                  <Link to={secondaryPlaylist.url} key={secondaryPlaylist.title} underline="always">
                    {secondaryPlaylist.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <Button
            component={GatsbyLink}
            to="/premium/library"
            variant="contained"
            color="primary"
            className={classes.heroButton}
          >
            {playlistsButtonTitle}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

PremiumContentHero.propTypes = {
  playlistsOverline: PropTypes.string.isRequired,
  guidesOverline: PropTypes.string.isRequired,
  playlistsSubtitle: PropTypes.string.isRequired,
  playlistsButtonTitle: PropTypes.string.isRequired,
  heroParshaLab: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.shape({
        html: PropTypes.string
      }).isRequired,
      episodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string
        })
      )
    })
  ).isRequired,
  heroGuides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      guides: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string
        })
      )
    })
  ).isRequired,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  featuredPlaylist: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.shape({}).isRequired,
    cover_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string
    }).isRequired,
    label_is_audio: PropTypes.string,
    label_is_new: PropTypes.string,
    label_is_producer: PropTypes.string,
    label_is_premium: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        length_in_seconds: PropTypes.number
      })
    ).isRequired
  })
};

PremiumContentHero.defaultProps = {
  featuredPlaylist: null
};

export default PremiumContentHero;
