import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Link from '~components/Link';
import Excerpt from '~components/Excerpt';
import {
  LabelNew,
  LabelAudio,
  LabelPremium,
  LabelProducer,
  LabelOverlay
} from '~components/playlist-card/PlaylistLabels';

import { boolFlagFromString, formatDurationOfFirstVideoTishaBav } from '~utils/playlist-helpers';
import { lineFix } from '~utils/popup-helpers';

const useStyles = makeStyles({
  playlistTitle: {
    fontSize: '22px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
    fontWeight: '500'
  },
  media: {
    display: 'flex',
    maxWidth: '100%'
  },
  infoText: {
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    '@media only screen and (max-width: 322px)': {
      display: 'block'
    }
  },
  excerpt: {
    '& p': {
      wordBreak: 'break-word',
      lineHeight: '22px',
      marginBlockStart: '10px'
    }
  },
  dotStyle: {
    fontSize: '0.6rem',
    marginLeft: '5px',
    marginRight: '5px'
  }
});

const PremiumContentPlaylistCard = ({
  playlist: {
    url,
    title,
    description,
    cover_image,
    author,
    label_is_new,
    label_is_audio,
    label_is_premium,
    label_is_producer,
    videos
  }
}) => {
  const classes = useStyles();
  const isNew = boolFlagFromString(label_is_new);
  const isAudio = boolFlagFromString(label_is_audio);
  const isPremium = boolFlagFromString(label_is_premium);
  const isProducer = boolFlagFromString(label_is_producer);

  const playlistVideosInfo =
    videos.length > 1 ? (
      <>
        Part 1 of {videos.length} <span className={classes.dotStyle}>&#x25cf;</span>
      </>
    ) : null;

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item md={3}>
          <Link to={url} underline="hover" color="textPrimary" style={{ width: '100%' }}>
            <img src={cover_image.url} alt={cover_image.alt} className={classes.media} />
          </Link>
        </Grid>
        <Grid item md={8}>
          <Typography className={classes.playlistTitle}>
            <Link to={url} underline="hover" color="textPrimary">
              {lineFix(title)}
            </Link>
          </Typography>

          <Typography variant="subtitle2" className={classes.infoText}>
            {author} <span className={classes.dotStyle}>&#x25cf;</span> {playlistVideosInfo}
            {formatDurationOfFirstVideoTishaBav(videos[0].length_in_seconds)} video
          </Typography>

          <Excerpt description={description.text} buttons={false} className={classes.excerpt} />

          <LabelOverlay style={{ position: 'unset', marginLeft: '-4px' }}>
            {isNew && <LabelNew />}
            {isAudio && <LabelAudio />}
            {isPremium && <LabelPremium />}
            {isProducer && <LabelProducer />}
          </LabelOverlay>
        </Grid>
      </Grid>
    </>
  );
};
PremiumContentPlaylistCard.propTypes = {
  playlist: PropTypes.shape({
    author: PropTypes.string.isRequired,
    url: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    cover_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string
    }),
    description: PropTypes.shape({
      text: PropTypes.string,
      html: PropTypes.string
    }),
    label_is_new: PropTypes.string,
    label_is_audio: PropTypes.string,
    label_is_premium: PropTypes.string,
    label_is_producer: PropTypes.string,
    videos: PropTypes.arrayOf(PropTypes.shape({ length_in_seconds: PropTypes.number.isRequired }))
  })
};
PremiumContentPlaylistCard.defaultProps = {
  playlist: {}
};

export default PremiumContentPlaylistCard;
