import { extractKnownSlices } from '~utils/unfurl-helpers';
import { get } from '../utils/get';

const extractHeroGuides = prismicData => {
  const guidesData = prismicData.guides_data || [];

  const guides = guidesData.map(guide => {
    const first_guide = get(guide, 'first_guide.document');
    const second_guide = get(guide, 'second_guide.document');
    return {
      title: guide.guides_title,
      subtitle: guide.guides_subtitle,
      guides: [
        {
          title: first_guide.data.title,
          url: first_guide.data.file.url,
          required_subscription_plan: first_guide.data.required_subscription_plan
        },
        {
          title: second_guide.data.title,
          url: second_guide.data.file.url,
          required_subscription_plan: second_guide.data.required_subscription_plan
        }
      ]
    };
  });

  return guides;
};

const extractHeroParshaLab = prismicData => {
  const parshaLabData = prismicData.parsha_lab_episodes || [];

  const parshaLabEpisodes = parshaLabData.map(parshaLab => ({
    title: parshaLab.parsha_lab_title,
    subtitle: parshaLab.parsha_lab_subtitle,
    episodes: [
      {
        url: get(parshaLab.first_parsha_lab_episode, 'url', null),
        title: get(parshaLab.first_parsha_lab_episode, 'document.data.title', null)
      },
      {
        url: get(parshaLab.second_parsha_lab_episode, 'url', null),
        title: get(parshaLab.second_parsha_lab_episode, 'document.data.title', null)
      }
    ]
  }));

  return parshaLabEpisodes;
};

const extractHeroFeaturedPlaylist = prismicData => {
  const featuredPlaylist = get(prismicData, 'hero_featured_playlist', null);

  if (!featuredPlaylist) {
    return null;
  }

  return {
    url: get(featuredPlaylist, 'url', ''),
    uid: get(featuredPlaylist, 'document.uid', ''),
    ...get(featuredPlaylist, 'document.data')
  };
};

const extractHeroPlaylists = prismicData => {
  const heroPlaylists = get(prismicData, 'hero_playlists', []);
  return heroPlaylists
    .map(playlistDataWrapper => {
      const url = get(playlistDataWrapper, 'hero_playlist.document.url', '');
      if (!url) {
        return null;
      }
      const title = get(playlistDataWrapper, 'hero_playlist.document.data.title', '');
      return { url, title };
    })
    .filter(item => !!item);
};

const extractHeroData = prismicData => ({
  playlistsOverline: prismicData.hero_playlists_overline_text,
  guidesOverline: prismicData.hero_guides_overline_text,
  playlistsSubtitle: prismicData.playlists_text,
  heroGuides: extractHeroGuides(prismicData),
  heroParshaLab: extractHeroParshaLab(prismicData),
  playlists: extractHeroPlaylists(prismicData),
  featuredPlaylist: extractHeroFeaturedPlaylist(prismicData),
  playlistsButtonTitle: prismicData.library_button_title
});

const extractImageSectionData = prismicData => ({
  title: prismicData.section_title,
  subtitle: prismicData.section_text,
  image: prismicData.section_image
});

const extractTextSectionData = prismicData => ({
  title: prismicData.text_section_title,
  subtitle: prismicData.text_section_text
});

const extractAudioPlaylists = prismicData => {
  const audioPlaylists = get(prismicData, 'body1[0]', null);
  if (!audioPlaylists) {
    return null;
  }

  return {
    title: audioPlaylists.primary.section_title,
    playlists: audioPlaylists.items.map(item => ({
      ...item.playlist.document.data,
      id: item.playlist.id,
      uid: item.playlist.uid,
      url: item.playlist.url,
      videos: get(item.playlist, 'document.data.videos', []).map(video =>
        get(video, 'video.document.data')
      ),
      author: get(item.playlist, 'document.data.author.document.data.name')
    })),
    libraryLinkTitle: prismicData.library_link_title
  };
};

const extractStaffPicksData = prismicData => ({
  title: prismicData.staff_section_title,
  subtitle: prismicData.staff_section_subtitle,
  background_color: prismicData.staff_section_background_color,
  staffPicks: prismicData.staff_picks.map(pick => ({
    avatar: get(pick.author, 'document.data.avatar'),
    title: pick.staff_pick_title,
    text: pick.staff_pick_text,
    button: {
      url: pick.staff_member_pick_url,
      title: pick.author_button_title
    }
  }))
});

export default prismicData => ({
  hero: extractHeroData(prismicData),
  image_section: extractImageSectionData(prismicData),
  text_section: extractTextSectionData(prismicData),
  audio_playlists: extractAudioPlaylists(prismicData),
  staffPicks: extractStaffPicksData(prismicData),
  seo: {
    seo_title: get(prismicData, 'seo_title', ''),
    seo_description: get(prismicData, 'seo_description', ''),
    seo_keywords: get(prismicData, 'seo_keywords', ''),
    seo_image: get(prismicData, 'seo_image.url', '')
  },
  slices: extractKnownSlices(prismicData, 'body')
});
