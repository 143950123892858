import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import Divider from '~components/Divider';
import Link from '~components/Link';
import PremiumContentPlaylistCard from '../PremiumContentPlaylistCard';

const useStyles = makeStyles({
  infoText: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#2E72AC',
    marginBottom: '29px'
  },
  containerSpacing: {
    marginBottom: '100px'
  },
  linkSpacing: {
    margin: '0 auto',
    marginTop: '60px'
  }
});

const PremiumContentVideos = ({ title, playlists, libraryLinkTitle }) => {
  const classes = useStyles();

  return (
    <Container className={classes.containerSpacing}>
      <Typography variant="overline" className={classes.infoText}>
        {title}
      </Typography>
      {playlists &&
        playlists.map((playlist, index) => (
          <React.Fragment key={playlist.url}>
            <PremiumContentPlaylistCard playlist={playlist} />
            {index + 1 !== playlists.length && <Divider />}
          </React.Fragment>
        ))}
      <Link
        variant="subtitle1"
        underline="always"
        to="/premium/library"
        className={classes.linkSpacing}
      >
        {libraryLinkTitle}
      </Link>
    </Container>
  );
};

PremiumContentVideos.propTypes = {
  title: PropTypes.string.isRequired,
  libraryLinkTitle: PropTypes.string.isRequired,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string.isRequired,
      url: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.string,
      cover_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string
      }),
      description: PropTypes.shape({
        text: PropTypes.string,
        html: PropTypes.string
      }),
      label_is_new: PropTypes.string,
      label_is_audio: PropTypes.string,
      label_is_premium: PropTypes.string,
      label_is_producer: PropTypes.string,
      videos: PropTypes.arrayOf(PropTypes.shape({ length_in_seconds: PropTypes.number.isRequired }))
    })
  ).isRequired
};

export default PremiumContentVideos;
